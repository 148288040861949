import React from 'react'
import { Helmet } from 'react-helmet'

import * as styles from './styles/lowes-page.module.css'
import { Link } from 'gatsby'
import SynapseLogo from '../components/icons/SynapseLogo'
import ArrowRight from '../components/icons/ArrowRight'
import SEO from '../components/seo'

const Lowes = () => {
  return (
    <>
      <SEO title="Continuous Discovery & Planning Best Practices" />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/oxz6qya.css" />
      </Helmet>
      <div className={styles.leftHex}></div>
      <div className={styles.rightHex}></div>
      <div className={styles.background}>
        <div className={styles.main}>
          <div className={styles.wrapper}>
            <div className={styles.columns}>
              <div className={styles.mainContent}>
                <h3>Lowe's Product Blueprint Presentation</h3>
                <h1>Continuous Discovery &amp; Planning Best Practices</h1>
                <p>
                  With over 100+ products launched across various industries,
                  we've fine-tuned our processes to ensure success. In this
                  workshop, participants explored how to continuously refine
                  their discovery and planning practices to align with customer
                  needs and drive business outcomes. Key takeaways included:
                </p>
                <ul>
                  <li>
                    Implementing continuous discovery to stay aligned with
                    evolving customer demands.
                  </li>
                  <li>
                    Structuring opportunity solution trees to systematically
                    address key challenges.
                  </li>
                  <li>
                    Integrating discovery insights into outcome-based roadmaps
                    for more effective planning.
                  </li>
                </ul>
              </div>
              <div className={styles.sidebar}>
                <div className={styles.headshot}>
                  <div className={styles.headshotIntro}>
                    <div className={styles.image}>
                      <img
                        src="/images/kim.png"
                        alt="Kim Stearns - Director of Product @ Synapse Studios"
                        width="175"
                      />
                    </div>
                    <div>
                      <h2>Kim Stearns</h2>
                      <h3>Director of Product @ Synapse Studios</h3>
                    </div>
                  </div>
                  <p>
                    With 10 years of product leadership, Kim has launched over
                    75 products and coached global product teams, helping
                    tech-enabled companies build and grow world-class products
                    and supportive product cultures.
                  </p>
                  <p>
                    <a
                      href="https://calendly.com/kim-synapse/discovery"
                      target="new"
                      rel="nofollow"
                      className={styles.bookButton}
                    >
                      <span>Book a Free Discovery Call</span>
                      <span>
                        <ArrowRight fill="#1ec2ad" />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.flourish}></div>
        <div className={styles.secondary}>
          <div className={styles.wrapper}>
            <div className={styles.columns}>
              <div className={styles.mainContent}>
                <h2>Presentation Resources &amp; Links</h2>
                <p>
                  Access the materials and resources from the workshop to
                  enhance your planning and discovery strategies:
                </p>
                <div className={styles.resources}>
                  <div className={styles.resourceWrapper}>
                    <a
                      href="/Lowe's Continuous Discovery & Planning Best Practices_Synapse Studios.pdf"
                      target="new"
                    >
                      <img
                        className={styles.resourceImg}
                        src="/images/presentation.png"
                        alt="Lowe's Presentation Deck sample"
                        width="100%"
                      />
                      <div className={styles.resourceText}>
                        <div>
                          <h3>Presentation Deck</h3>
                          <span>PDF</span>
                        </div>
                        <div>
                          <ArrowRight />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className={styles.resourceWrapper}>
                    <a
                      href="https://www.figma.com/board/G77aUQaWLSe3gd3BrAjLUO/Continuous-Discovery-Habits-Workshop?node-id=157-51859&t=cLl0fXq1VXcrMd3b-4"
                      target="new"
                    >
                      <div className={styles.resourceText}>
                        <div>
                          <h3>
                            OST Template
                            <br />
                            <span
                              style={{ fontSize: '15px', fontWeight: '500' }}
                            >
                              Lowe's Employees Only
                            </span>
                          </h3>
                          <span>Figma</span>
                        </div>
                        <div>
                          <ArrowRight />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className={styles.resourceWrapper}>
                    <a
                      href="https://www.figma.com/board/G77aUQaWLSe3gd3BrAjLUO/Continuous-Discovery-Habits-Workshop?node-id=60-1764&t=cLl0fXq1VXcrMd3b-4"
                      target="new"
                    >
                      <div className={styles.resourceText}>
                        <div>
                          <h3>Template File</h3>
                          <span>Figjam</span>
                        </div>
                        <div>
                          <ArrowRight />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.sidebarWithPadding}>
                <h2>Recent Articles by Kim</h2>
                <ol>
                  <li>
                    <Link to="/learn/women-in-tech">
                      <p>
                        7 Strategies for Accelerating the Rise of Women in
                        Technical Leadership at Your Organization
                      </p>
                    </Link>
                    <span>
                      Business <em>&bull;</em> 9 min read
                    </span>
                  </li>
                  <li>
                    <Link to="/learn/business-process-mapping">
                      <p>
                        7 Steps to Ensure an Effective Business Process Audit
                        for Software Development
                      </p>
                    </Link>
                    <span>
                      Product management <em>&bull;</em> 5 min read
                    </span>
                  </li>
                  <li>
                    <Link to="/learn/chasing-ghosts-what-app-developers-get-wrong-about-their-customers">
                      <p>
                        Chasing Ghosts: What App Developers Get Wrong About
                        Their Customers
                      </p>
                    </Link>
                    <span>
                      Product <em>&bull;</em> 5 min read
                    </span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.wrapper}>
            <div>
              <Link to="/">
                <SynapseLogo fill="#fff" />
              </Link>
              <ul>
                <li>
                  <Link to="/work">Work</Link>
                </li>
                <li>
                  <Link to="/learn">Learn</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div>
              <span>&copy; 2024 Synapse Studios</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Lowes
